


















































































































































































































































































































































































































































































































































































































































import truncate from 'vue-truncate-collapsed';
import { Component, Vue, Watch } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import { getComponent, getConfigEnv } from '@/utils/helpers';
import { DateTime } from 'luxon';
import { getNameByEmail } from '@/utils/users';
import workflowModule from '@/store/modules/workflowModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import { CHART_CLICK_EVENT, SHOW_ALERT } from '@/lib/constants';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';

@Component({
  components: {
    truncate,
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    SetpointFeedbackInfo: () => getComponent('tasqs/SetpointFeedbackInfo'),
    DefermentLabeling: () => getComponent('tasqs/DefermentLabeling'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
  },
})
export default class TasqFeedback extends mixins(DataLoading) {
  feedbackSteps = [
    {
      id: 1,
      number: 1,
      name: 'Validate',
      active: true,
    },
    {
      id: 2,
      number: 2,
      name: 'Label',
      active: false,
    },
    {
      id: 3,
      number: 3,
      name: 'Action',
      active: false,
    },
    {
      id: 4,
      number: 4,
      name: 'Resolution',
      active: false,
    },
  ];

  defermentLabelingModal = false;

  stepper = 1;

  selectionAction = '';

  stepOneVariant = '';

  datetime = '';

  selectionActions = [
    {
      text: 'Yes',
      value: 'YES',
    },
    {
      text: 'No',
      value: 'NO',
    },
    {
      text: 'Not sure',
      value: 'DONT_KNOW',
    },
  ];

  showComment = false;

  predictionAdditionalAction = '';

  comment = '';

  standardReasons = getConfigEnv('FEEDBACK_REASONS');

  reason = [];

  stepTwoVariant = '';

  jobAction = '';

  jobActions = [
    'On site fix',
    'On site replacement',
    'Change setpoint',
    'Troubleshooting',
    'No action needed',
  ];

  stepThreeVariant = '';

  foundProcedure = null;

  activityShown = 'history';

  activeEvent = '';


  getWellCommentResponseIcon(comment) {
    if (comment.ResponseData.UserResponse.Selection == "YES") {
      return "task_alt"
    } else if (comment.ResponseData.UserResponse.Selection == "NO") {
      return "arrow_downward"
    } else if (comment.ResponseData.UserResponse.Selection == "NOT_SURE") {
      return "quiz"
    }
  }

  getReadableWellHistoryComment(comment) {
    var readableComment = "Confirmed"
    if (comment.ResponseData.UserResponse.Selection == "YES") {
      var readableComment = "Confirmed"
    } else if (comment.ResponseData.UserResponse.Selection == "NO") {
      var readableComment = "No"
    } else if (comment.ResponseData.UserResponse.Selection == "NOT_SURE") {
      var readableComment = "Not sure of"
    }

    if (this.tasq.predictionType.toLowerCase() == "anomaly" || this.tasq.predictionType.toLowerCase() == "state change" || this.tasq.predictionType.toLowerCase() == "prolonged anomaly") {
      readableComment = readableComment + " Anomaly"
    } else if (this.tasq.predictionType.toLowerCase() == "off-target") {
      readableComment = readableComment + " Off-Target"
    }

    if (comment.ResponseData.UserResponse.SelectionDetails.Reason.length > 0) {
      readableComment = readableComment + ": "
      for (var x = 0; x < comment.ResponseData.UserResponse.SelectionDetails.Reason.length; x++) {
        readableComment = readableComment + " " + comment.ResponseData.UserResponse.SelectionDetails.Reason[0]
      }

    }
    return readableComment
  }

  get tasq() {
    return tasqsListModule.activeTasq as TasqJob;
  }

  getFormattedDatetime() {
    if (!this.datetime) return '';
    return DateTime.fromISO(this.datetime).toFormat('hha, LLL d, y');
  }

  get tasqResponseData() {
    if (workflowModule.wellResponseData) {
      return workflowModule.wellResponseData
    }
    return null
  }


  get nextButtonTitle() {
	if (this.tasq.isManuallyAdded) {
      return "Finish";
    }
	return "Continue"
  }


  get identifyingSubText() {
	if (this.tasq.isManuallyAdded) {
      return "Has this " + this.tasq.predictionType + " job been resolved?";
    }
	return "This response helps Tasq learn"
  }



  get identifyingText() {
	  console.log("TAGGSQ ")
	  console.log(this.tasq)
    if ((this.tasq || {}).noComms) return 'Has this been resolved?';
    if ((this.tasq || {}).wellTestData) return 'Has this well test been completed?';
    if (
      this.tasq.engineerType === this.$getConst('TASQ_PROLONGED_ANOMALY_TYPE')
      && !this.tasqResponseData?.ResponseData?.UserResponse?.SelectionDetails?.Reason?.[0] // eslint-disable-line
    ) {
      return 'Is there a change in well behavior?';
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_WORKFLOW_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_PROLONGED_ANOMALY_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')
      || this.tasq.predictionType === 'Reject this'
    ) return 'What action was taken?';
    if ((this.tasqResponseData || {}).ResponseData) return 'Please update your feedback:';
    if (this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')) {
      return 'Is there a change in well behavior?';
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
      return 'Is this well off target?';
    }
	if (this.tasq.isManuallyAdded) {
      return this.tasq.predictionType;
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_PREDICTION_TYPE')) {
      return 'What would you like to do?';
    }
    return this.tasq.engineerType === 'issue' || !this.tasq
      ? 'Is there an issue here?' : `Did Tasq correctly identify the ${ this.tasq?.engineerType }?`;
  }

  get activeActionText() {
    let text = '';
    if (
      this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')
      || (this.tasq.engineerType === this.$getConst('TASQ_COMPLETED_TYPE') && this.tasq.predictionType === 'state change')
    ) {
      if (this.selectionAction === this.selectionActions[0].value) {
        text = 'Please select type of change:';
      }
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_FAILURE_TYPE')) {
      if (this.selectionAction === this.selectionActions[1].value) {
        text = 'Please select the reason:';
      }
    }
    if (this.tasq.engineerType === 'issue') {
      if (this.selectionAction === this.selectionActions[0].value) {
        text = 'Please select the reason:';
      }
    }
    return text;
  }

  get possibleReasons() {
    let reasons = this.standardReasons;
    if (
      this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE')
      || this.tasq.engineerType === 'issue'
      || (this.$getConst('TASQ_COMPLETED_TYPE') && this.tasq.predictionType === 'state change')
      || (this.$getConst('TASQ_OFF_TARGET_TYPE') && this.tasq.predictionType === this.$getConst('TASQ_OFF_TARGET_TYPE'))
    ) {
      reasons = this.standardReasons;
      if (this.tasq.predictionType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
        reasons.push('Data issue');
        reasons.push('Downhole solids');
      }
    }
    return reasons;
  }


  get events() {
    return tasqsListModule.tasqEventHistoryList
    // return tasqProductionDataChartModule.wellHistory;
  }

  get getWellHistory() {
    return workflowModule.wellHistory
  }

  created() {
    // workflowModule.getWellHistory(this.tasq.wellName)
    this.$eventBus.$on(CHART_CLICK_EVENT, (date) => {
      if (this.stepper === 4) {
        this.datetime = date;
      }
    })
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async resetDefermentPage() {
    this.defermentLabelingModal = false;
    await this.sleep(0.1)
    this.defermentLabelingModal = true;
  }

  handleStepOne(omitDefermentLabeling = false) {
	  if (this.tasq.isManuallyAdded) {
		  this.selectionAction = "YES"
		  this.leaveFeedback(true, true, true);
		  return
	  }
    if (!this.selectionAction) {
      this.stepOneVariant = 'error';
      return;
    }
    if (this.selectionAction === 'WAIT_AND_SEE') {
      // this.$store.dispatch(ADD_TO_WATCH_LIST, {
      //   tasq: this.tasq,
      // });
      this.$router.push({
        name: 'Tasqs',
      });
      return;
    }
    if (this.selectionAction === 'NO') {
      if (this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE') && !omitDefermentLabeling) {
        // this.$dialog.show('defermentLabelingModal');
        this.defermentLabelingModal = true;
        return;
      }
      this.leaveFeedback(true, true, true);
      return;
    }
    tasqFeedbackModule.leaveFeedback({
      tasq: this.tasq,
      selection: this.selectionAction,
    });
    this.stepper = 2;
    this.feedbackSteps[1].active = true;
  }

  handleStepTwo() {
    if (!this.reason.length) {
      this.stepTwoVariant = 'error';
      return;
    }
    if (this.predictionAdditionalAction === 'Failure did not occur') {
      this.selectionAction = this.selectionActions[1].value; // NO
      this.leaveFeedback(true, true);
      return;
    }
    if (this.predictionAdditionalAction === 'Validate failure now') {
      this.selectionAction = this.selectionActions[0].value; // YES
    }
    if (this.predictionAdditionalAction === 'Failure occurred previous to prediction') {
      this.selectionAction = this.selectionActions[1].value; // NO
    }
    tasqFeedbackModule.leaveFeedback({
      tasq: this.tasq,
      selection: this.selectionAction,
      reason: this.reason,
      comment: this.comment,
    });
    this.stepper = 3;
    this.feedbackSteps[2].active = true;
  }

  handleStepThree() {
    tasqFeedbackModule.leaveFeedback({
      tasq: this.tasq,
      selection: this.selectionAction,
      reason: this.reason,
      correctedStateChangeDate: this.datetime || '',
      comment: this.comment,
      jobAction: this.jobAction,
      markAsComplete: true,
    });
    this.stepper = 4;
  }

  handleStepFour() {
    if (this.tasq.predictionType === this.$getConst('TASQ_FAILURE_PREDICTION_TYPE')) {
      this.stepper = 4;
      return;
    }
    this.leaveFeedback(true, true);
  }

  // eslint-disable-next-line consistent-return
  handlePossibleActionsChange(value) {
    if (this.tasq.predictionType === this.$getConst('TASQ_FAILURE_PREDICTION_TYPE')) {
      return value;
    }
    // this.getFoundProcedure();
  }

  handleStepClick(step) {
    if (!step.active) return;
    this.stepper = step.id;
  }

  handleTasqChange() {
    this.startDataLoading();
    this.resetFeedback();
    if (this.tasqResponseData && this.tasqResponseData.ResponseData) {
      const userSelection = this.tasqResponseData.ResponseData.UserResponse.Selection;
      const date = this.tasqResponseData.ResponseData.UserResponse.CorrectedStateChangeDate;
      this.selectionAction = userSelection;
      this.reason = this.tasqResponseData.ResponseData.UserResponse.SelectionDetails.Reason;
      this.datetime = date;
      this.jobAction = this.tasqResponseData.ResponseData.UserResponse.SelectionDetails.Action?.[0];
    }
    if (this.tasqResponseData && this.tasqResponseData.stepOneCompleted) {
      this.feedbackSteps[1].active = true;
      this.stepper = 2;
    }
    if (this.tasqResponseData && this.tasqResponseData.stepTwoCompleted) {
      this.feedbackSteps[2].active = true;
      this.stepper = 3;
    }
    if (this.tasqResponseData && this.tasqResponseData.stepThreeCompleted) {
      if (this.tasq.completed) {
        this.stepper = 3;
      } else {
        this.feedbackSteps[3].active = true;
        this.stepper = 4;
      }
    }
    if (this.tasqResponseData && this.tasqResponseData.stepFourCompleted) {
      this.stepper = 4;
    }

    if (this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')) {
      this.selectionActions = [ {
        text: 'Updated setpoint',
        value: 'YES',
      }, {
        text: 'Not now',
        value: 'NO',
      } ];
    } else if (this.tasq.engineerType === this.$getConst('TASQ_WELL_TEST_TYPE')) {
      this.selectionActions = [
        {
          text: 'Yes',
          value: 'YES',
        },
        {
          text: 'No',
          value: 'NO',
        },
      ];
    } else if (this.tasq.isManuallyAdded) {
      this.selectionActions = [];
    } else {
      this.selectionActions = [
        {
          text: 'Yes',
          value: 'YES',
        },
        {
          text: 'No',
          value: 'NO',
        },
        {
          text: 'Not sure',
          value: 'DONT_KNOW',
        },
      ];
    }
    this.stopDataLoading();
  }

  resetFeedback() {
    this.stepper = 1;
    this.selectionAction = '';
    this.datetime = '';
    this.jobAction = '';
    this.comment = '';
    this.reason = [];
    this.showComment = false;
  }

  async leaveFeedback(
    skipDateTime = false,
    markAsComplete = false,
    shouldPostJobComment = false,
    completeForToday = false,
  ) {
    if (this.datetime || skipDateTime) {
      this.startDataLoading();
      // this.actionSelected = true;
      await tasqFeedbackModule.leaveFeedback({
        tasq: this.tasq,
        selection: this.selectionAction,
        reason: this.reason,
        correctedStateChangeDate: this.datetime || '',
        comment: this.comment,
        // procedure: this.foundProcedure,
        jobAction: this.jobAction,
        markAsComplete,
        shouldPostJobComment,
        completedForToday: completeForToday
      });
      await tasqsListModule.getTasqs({ val: tasqsListModule.activeListType });
      this.stopDataLoading();
      this.$router.push({ name: 'Tasqs' });
      this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');
    }

  }

  toggleShowActivity(activity) {
    this.activityShown = activity;
  }

  showEventOnChart(event) {
    return event;
  }

  openStepDetails(step) {
    (this.foundProcedure as any).steps.forEach((i) => {
      if (step.id !== i.id) {
        Object.assign(i, { showDetails: false });
      }
    });
    Object.assign(step, { showDetails: !step.showDetails });
  }

  updateTasqTime() {
    this.$dialog.show('productionDataModal');
  }

  @Watch('tasq.id', {
    immediate: true,
  })
  onTasqChange() {
    this.handleTasqChange();
  }


  @Watch('tasqResponseData', {
    immediate: true,
  })
  onTasqResponseChange() {
    this.handleTasqChange();
  }
}
