import gql from 'graphql-tag';
import { getConfigEnv } from '@/utils/helpers';

const mutation = gql`
  mutation post_job_response($input: UpdatePredictionResponseTableInput!) {
    post_job_response (input: $input) {
	ID
  }
  }
`;

export default mutation;
