import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { v4 as uuidv4 } from 'uuid';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import userResponsesApolloClient from '@/lib/appsync/workflow';
import updateUserResponse from '@/graphql/userResponses/mutations/updateUserResponse';
import postComment from '@/graphql/workflow/mutations/postComment.graphql';
import postJobComment from '@/graphql/workflow/mutations/postJobComment.graphql';

import accountModule from '@/store/modules/accountModule';
import workflowApolloClient from '@/lib/appsync/workflow';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqFeedbackModule',
  store,
})
class TasqFeedbackModule extends VuexModule {
  availableSignals = getConfigEnv('SIGNALS_ARRAY');

  currentSignals: any = [];

  @Mutation
  resetSignals(): void {
    this.currentSignals = [];
  }

  @Mutation
  addSignal(signal): void {
    this.currentSignals.push(signal);
  }

  @Action
  async leaveFeedback({
    tasq,
    selection,
    reason = [],
    correctedStateChangeDate = '',
    comment = '',
    procedure = null,
    jobAction = '',
    markAsComplete = false,
    shouldPostJobComment = false,
	completedForToday = false
  }) {
    try {
      // const startTime = new Date().getTime();
      let commentId = '';

	  console.log("reasonreason: ")
	  console.log(reason)
	  console.log("jobActionjobAction: ")
	  console.log(jobAction)
      const responseData = {
        // user_id: accountModule.user.email,
        NodeID: tasq.wellName,
		ResponseID: uuidv4(),
        UserResponse: {
          Selection: selection,
          SelectionDetails: {
            Reason: reason,
            // @ts-ignore
            ...jobAction && {
              Action: [jobAction],
            },
          },
          ...correctedStateChangeDate && {
            CorrectedStateChangeDate: correctedStateChangeDate,
          },
        },
      };

	  var input = {
		WorkflowTaskID: tasq.workflowTasqId,
		NodeID: tasq.wellName,
		PredictionID: tasq.id,
		ResponseData: {
		  ...responseData,
		  ResponseID: uuidv4(),
		},
		MarkAsComplete: markAsComplete,
		Operator: getConfigEnv('OPERATOR_LOWERCASED'),
	  }
	  console.log("TASQ VALUE::::")
	  console.log(tasq)
	  if (completedForToday) {
		  // @ts-ignore
		input.CompletedForToday = true
	  }
	  if (tasq.wellTestData && tasq.wellTestData.taskID != null && tasq.wellTestData.ganttChartID != null) {
		  // @ts-ignore
		input.GanttChartID = tasq.wellTestData.ganttChartID
		// @ts-ignore
		input.TaskID = tasq.wellTestData.taskID
	  }






	//   comment: String
	//   prediction_id: String!
	//   response_data: UserResponseDataInput
	//   should_post_as_global_well_comment: Boolean
	//   username: String!

	  console.log(" JJJJJJFFFFFF")
	  console.log({
		prediction_id: tasq.id,
		username: accountModule.user.email,
		comment,
		response_data: responseData,
		should_post_as_global_well_comment: false,
	  })

	  console.log(" BFBBFBBFBBF ")
	  console.log({
		node_id: tasq.wellName,
		username: accountModule.user.email,
		job_type: tasq.predictionType,
		comment,
		operator: getConfigEnv('OPERATOR_LOWERCASED'),
		response_data: responseData,
		prediction_id: tasq.id,
		comment_id: commentId,
	  })



      await userResponsesApolloClient.mutate({
        mutation: updateUserResponse,
        variables: {
          input: input,
        },
      });
      if (shouldPostJobComment) {
        const {
          data: {
            post_job_comment: {
              comment_id: jobCommentId,
            },
          },
        } = await workflowApolloClient.mutate({
          mutation: postJobComment,
          variables: {
            input: {
              prediction_id: tasq.id,
              username: accountModule.user.email,
              comment,
              response_data: responseData,
              should_post_as_global_well_comment: false,
            },
          },
        });
        commentId = jobCommentId;
      }
      if (markAsComplete && commentId) {
        await workflowApolloClient.mutate({
          mutation: postComment,
          variables: {
            input: {
              node_id: tasq.wellName,
              username: accountModule.user.email,
              job_type: tasq.predictionType,
              comment,
              operator: getConfigEnv('OPERATOR_LOWERCASED'),
              response_data: responseData,
              prediction_id: tasq.id,
              comment_id: commentId,
            },
          },
        });
        tasqsListModule.updateTasq({
          id: tasq.id,
          data: {
            completed: true,
          },
        });
      }
      if (procedure) {
        // dispatch(POST_PROCEDURE_RESPONSE, { tasq, procedure, showSnackbar: true });
        // showSystemElement({
        //   type: SHOW_SYSTEM_SNACKBAR,
        //   snackBarText: 'Saving feedback...',
        //   color: '#0076FF',
        //   showUndo: false,
        //   closable: false,
        //   timeout: 1000,
        //   showLoading: true,
        // });
        // if (tasq.isLocked) {
        //   dispatch(LOCK_TASQ, { tasq });
        // }
      }
      // const endTime = new Date().getTime();
      // postLoadTime(endTime - startTime, 'TASQ_LIST');
      // commit(SET_ACTIVE_TASQ, JSON.parse(JSON.stringify(tasq)));
      // commit(LEAVE_TASQ_FEEDBACK, { tasq, responseData });
    } catch (e) {
      // showApplicationError('Error while leaving feedback');
      // router.push({ name: 'Tasqs' });
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }
}

export default getModule(TasqFeedbackModule);
